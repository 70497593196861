
import {Options, Vue} from 'vue-class-component'
import {ElMessageBox} from "element-plus";

@Options({
  data () {
    return {
      showProgress: false,
      downloadPercentage: 0
    }
  },
  mounted() {
    const self = this

    if (window.checkUpdate) {
      window.checkUpdate()
    }

    if (window.setAvailableUpdateCallback) {
      window.setAvailableUpdateCallback(function () {
        ElMessageBox.confirm(
            '发现新版本，现在升级？',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }
        ).then(() => {
          window.downloadUpdate()
        }).catch(() => {
          console.log('Canceled')
        })
      })
    }

    if (window.setDownloadProgressCallback) {
      window.setDownloadProgressCallback(function (arg: any) {
        self.showProgress = true
        self.downloadPercentage = Math.round(arg.percent - 0)
      })
    }

    if (window.setUpdateCallback) {
      window.setUpdateCallback(function () {
        self.showProgress = false
        self.downloadPercentage = 0

        ElMessageBox.confirm(
            '下载完成，现在安装？',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }
        ).then(() => {
          window.updateNow()
        }).catch(() => {
          console.log('Canceled')
        })
      })
    }
  }
})
export default class App extends Vue {}
