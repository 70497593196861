import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'

import 'element-plus/theme-chalk/index.css'
import 'element-plus/theme-chalk/el-loading.css'
import 'element-plus/theme-chalk/el-message.css'
import 'element-plus/theme-chalk/el-message-box.css'

import zhLocale from 'element-plus/lib/locale/lang/zh-cn'

import { DraggablePlugin } from '@braks/revue-draggable'

createApp(App)
    .use(store)
    .use(router)
    .use(ElementPlus, { locale: zhLocale })
    .use(DraggablePlugin)
    .mount('#app')
